import React, { useEffect } from 'react';
import { Script } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Pricing from '../components/pricing';
import Faq from '../components/faq';
import featureImage from '../../static/images/hero-image.png';
import accountabilityPartner from '../../static/images/workout.jpg';
import programming from '../../static/images/monthly-visits-2025.jpg';
import monthlyVisits from '../../static/images/workout-two.jpg';
import fuelRx from '../../static/images/nutrition.jpg';
import saveMoney from '../../static/images/kb-vertical.jpg';

function initializeHubspotForm() {
  if ('hbspt' in window) {
    window.hbspt.forms.create({
      region: 'na1',
      portalId: '21009557',
      formId: 'ad7897a4-36b2-46be-b0b6-96600773153a',
      target: '.formContainer',
    });
  } else {
    setTimeout(initializeHubspotForm, 500);
  }
}

const shouldHideForm = true;
const isPageExpired = true;

const IndexPage = () => {
  useEffect(() => {
    if (!shouldHideForm) {
      initializeHubspotForm();
    }

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function(e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
  }, []);

  if (isPageExpired) {
    return (
      <Layout isPageExpired={isPageExpired}>
        <div
          style={{
            maxWidth: 800,
            margin: 'auto',
            minHeight: 500,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <div>
            <h1>Registration closed</h1>
            <p>
              If you are still interested in tackling your goals for 2025,
              contact our office at{' '}
              <a href='tel:+19726953575'>(972) 695-3575</a> or{' '}
              <a target='_blank' href='https://peakrxtherapy.com/contact'>
                submit a request online
              </a>{' '}
              to learn more about our membership options.{' '}
            </p>
          </div>
        </div>
      </Layout>
    );
  } else {
    return (
      <>
        <Script src='https://js.hsforms.net/forms/embed/v2.js' />
        <Layout>
          <SEO title='Make Your Body and Health a Priority in 2025' />

          <div className={'page-header home'}>
            <sup>DEADLINE: NOVEMBER 5, 2024</sup>
            <h1>Put Your Body & Health First This Upcoming Year</h1>
            <p>
              The Peak Performance Plan is here and gives you access to a 12 month
              customized health and wellness plan allowing you to stay active,
              avoid injury and enjoy your life.
              <br />
            </p>
            <div className='hero-cta'>
              <a href='#form-area'>Sign Up Today</a>
            </div>
            <img style={{borderRadius: '10px'}} alt='' src={featureImage} />
          </div>

          <div className={'container'}>
            <div className={'features'}>
              <div className={'feature__item'}>
                <div className={'row'}>
                  <div className={'col-6'} style={{order: 2}}>
                    <div className={'thumbnail'}>
                      <img alt='Monthly Visits' src={monthlyVisits} />
                    </div>
                  </div>

                  <div className={'col-6 first'}>
                    <div className={'feature__content'}>
                      <h2>Monthly Visits</h2>
                      <p>
                        At our monthly visit we can do hands-on work like dry
                        needling, go over how to improve a movement like running,
                        or help modify your current workouts.
                        <br />{' '}
                        <br />
                        For my postpartum mommas, this is a great way to stay in
                        touch and progress you through your first year as you
                        continue to heal and progress back to normal life.
                        <br /> <br />
                        This is incredibly effective and allows us to avoid
                        injuries instead of waiting for them to occur. Best of
                        all, it's extremely time efficient. All we need is 1 hour,
                        once a month, to help keep you pain-free and active.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={'feature__item'}>
                <div className={'row'}>
                  <div className={'col-6 first'}>
                    <div className={'thumbnail'}>
                      <img alt='FuelRx' src={fuelRx} />
                    </div>
                  </div>

                  <div className={'col-6'}>
                    <div className={'feature__content'}>
                      <h2>FuelRx (Nutrition Coaching)</h2>
                      <p>
                        This year we are so pumped to offer your first 3 months of
                        our FuelRx subscription absolutely free!
                        <br />{' '}
                        <br />
                        FuelRX is 1 on 1 Nutrition coaching to help you identify goals, break down goals into manageable steps, guide sustainable behavior change and problem solve through the challenges along the way.
                      </p>
                      <br />
                      <p><strong style={{ fontWeight: 'bold' }}>FuelRx includes:</strong></p>
                      <ul style={{listStyle: 'circle'}}>
                        <li style={{lineHeight: '1.3', marginBottom: '10px'}}>Goal setting - you will work with your coach Haley  to create a tailored nutrition plan to meet your goals</li>
                        <li style={{lineHeight: '1.3', marginBottom: '10px'}}>Weekly direction and accountability check-ins with Haley to keep you on track</li>
                        <li style={{lineHeight: '1.3', marginBottom: '10px'}}>Regular inBody scans to help measure success and facilitate data driven decisions</li>
                        <li style={{lineHeight: '1.3', marginBottom: '10px'}}>Access to your nutrition coach for questions and support throughout the week</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className={'feature__item'}>
                <div className={'row'}>
                  <div className={'col-6'}>
                    <div className={'feature__content'}>
                      <h2>Practitioner Guided Programming</h2>
                      <p>
                      Customized programming specific to meet your goals. All exercises will be geared specifically towards you after an extensive mobility screen and evaluation is performed. <br />
                        <br />
                        Your program is issued to you through an easy to use app that includes videos with each exercise and the ability to message your therapist with questions throughout the month!


                      </p>
                    </div>
                  </div>

                  <div className={'col-6 first'}>
                    <div className={'thumbnail'}>
                      <img
                        alt='Practitioner Guided Programming'
                        src={programming}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={'feature__item'}>
                <div className={'row'}>
                  <div className={'col-6 first'}>
                    <div className={'thumbnail'}>
                      <img
                        alt='Accountability Partner'
                        src={accountabilityPartner}
                      />
                    </div>
                  </div>

                  <div className={'col-6'}>
                    <div className={'feature__content'}>
                      <h2>Accountability Partner</h2>
                      <p>
                      This is really underrated. Having a Doctor to email when you have questions and that you see monthly will help you reach your health/wellness goals.
                      <br />
                        <br />
                        Accountability makes all the difference and we're here to help you along the way.

                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={'feature__item'}>
                <div className={'row'}>
                  <div className={'col-6'}>
                    <div className={'feature__content'}>
                      <h2>Save Money</h2>
                      <p>
                        The Peak Performance Plan is only going to be available
                        once per year. You'll{' '}
                        <strong>save $1,000 signing up now</strong> vs buying 12
                        individual visits next year. <br />
                        <br />
                        This doesn't even include all of the other needless
                        medical visits and procedures you will avoid by signing
                        up to ensure you put your health and wellness first in
                        2025.
                        <br />
                        <br />
                        <span className='txt-red'>
                          Due to availability, we will only be taking on 25
                          patients for this plan in 2025.&nbsp;
                          <a href='#form-area' className='txt-red'>
                            Reserve your spot
                          </a>
                          &nbsp;before its too late!
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className={'col-6 first'}>
                    <div className={'thumbnail'}>
                      <img alt='Save Money' src={saveMoney} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='pricing'>
            <h1 className='txt-purple'>The Peak Performance Plan</h1>
            <Pricing />
          </div>

          <div id='form-area' className={'call-to-action'}>
            <div className={'container'}>
              <div>
                <h1 className='form-title' style={{ textAlign: 'center' }}>
                  👇 Register for the Peak Performance Plan 👇
                </h1>
                <p style={{ textAlign: 'center', color: '#333' }}>
                  Once you complete the form below, you will receive an email to
                  complete payment which will secure your spot.
                </p>
                {!shouldHideForm ? (
                  <div className='formContainer'></div>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <h2 style={{ color: '#3d52d5' }}>Signup begins soon!</h2>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='faq-block' id='faq'>
            <div
              className='txt-center'
              style={{
                marginBottom: '50px',
              }}
            >
              <h1>Frequently Asked Questions</h1>
            </div>
            <Faq />
          </div>
        </Layout>
      </>
    );
  }
};

export default IndexPage;
